import {
  Flex,
  Text,
  CallToAction,
  getDataIcons,
  Field,
  formDataAppender,
  useToast,
} from '@nex/labs';
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Router from 'next/router';

import PromptIcon from '@nex/icons/svg/blocks/prompt.svg';
import UploadIcon from '@nex/icons/svg/dashboard/image.svg';
import ResetIcon from '@nex/icons/svg/animator/reset.svg';

import { RadioButton } from '../radio-button/index';
import styles from './animate-form.module.scss';
import { useAnimateMutation } from '@/state/query/animator';
import {
  type ModelIdentifier,
  MODELS,
  MODEL_MINIMAX,
  SPECIAL_EFFECTS,
  CAMERA_MOVEMENTS,
  getDurations,
} from './constants';
import { usePostHog } from 'posthog-js/react';

type Values = {
  image?: string;
  duration?: string;
  description?: string;
  imagePreviewUrl?: string;
  cameraMovement?: string;
  specialEffect?: string;
  model?: ModelIdentifier;
};

export const AnimateForm = () => {
  const { createToast } = useToast();
  const { mutateAsync: animate, isLoading } = useAnimateMutation({
    onError: (error: any) => {
      createToast({
        message: error?.message ?? 'Something went wrong, please try again.',
        variant: 'error',
      });
    },
  });
  const [values, setValues] = useState<Values>({
    model: MODEL_MINIMAX,
  });

  useEffect(() => {
    setValues((prev) => {
      const durations = getDurations(prev.model as ModelIdentifier);

      return {
        ...prev,
        duration: durations[0].value,
      };
    });
  }, []);

  const handleModelChange = (event: any) => {
    handleValueUpdate({
      target: {
        name: 'model',
        value: event.target.value,
      },
    });
  };

  const handleDurationChange = (event: any) => {
    handleValueUpdate({
      target: {
        name: 'duration',
        value: event.target.value,
      },
    });
  };

  const posthog = usePostHog();

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const data = formDataAppender({
        image: values.image,
        description: values.description,
        duration: values.duration,
        cameraMovement: values.cameraMovement,
        specialEffect: values.specialEffect,
        model: values.model,
      });

      posthog.capture('generate_animation', {
        model: values.model,
        duration: values.duration,
        cameraMovement: values.cameraMovement,
        specialEffect: values.specialEffect,
      });
      const response = await animate(data);
      Router.replace(`/animator/${response.animation.id}`);
    },
    [values, posthog, animate]
  );

  const handleValueUpdate = (event: any) => {
    setValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const durations = useMemo(
    () => getDurations(values.model as ModelIdentifier),
    [values]
  );

  return (
    <Field.Form onSubmit={handleSubmit}>
      <div className={styles.AnimateFormImage}>
        {values.imagePreviewUrl ? (
          <ImagePreview
            url={values.imagePreviewUrl}
            onReset={() => {
              handleValueUpdate({
                target: {
                  name: 'imagePreviewUrl',
                  value: undefined,
                },
              });

              handleValueUpdate({
                target: {
                  name: 'image',
                  value: undefined,
                },
              });
            }}
          />
        ) : (
          <div className={styles.AnimateFormImageInput}>
            <Flex.Column gap={8} alignItems="center">
              <UploadIcon />
              <Text weight={700}>Upload Image</Text>
              <Text>Upload up to 10mb PNG/JPEG</Text>
            </Flex.Column>
            <CallToAction.input
              size="sm"
              type="button"
              disabled={isLoading}
              variant="secondary"
              className="w-full"
              defaultBehavior={false}
              leadingIcon={
                <img
                  src={getDataIcons('add', '#000')}
                  className="w-[12px] h-[12px]"
                />
              }
              onFileUpload={(file, options) => {
                handleValueUpdate({
                  target: {
                    name: 'image',
                    value: file[0],
                  },
                });

                if (options?.previews?.length) {
                  handleValueUpdate({
                    target: {
                      name: 'imagePreviewUrl',
                      value: options?.previews?.[0],
                    },
                  });
                }
              }}
            >
              Choose Image
            </CallToAction.input>
          </div>
        )}
      </div>

      <Field.Select
        label="Model"
        name="model"
        defaultValue={values.model}
        onChange={handleModelChange}
        options={MODELS}
        required
      />

      <Field.Select
        label="Duration"
        name="duration"
        onChange={handleDurationChange}
        disabled={!durations.length}
        options={durations}
        required
      />

      <RadioButton
        name="cameraMovement"
        label="Camera Movement"
        options={CAMERA_MOVEMENTS}
        currentValue={values.cameraMovement}
        onChange={handleValueUpdate}
      />

      <RadioButton
        name="specialEffect"
        label="Special Effect"
        options={SPECIAL_EFFECTS}
        currentValue={values.specialEffect}
        onChange={handleValueUpdate}
      />

      <Field.Textarea
        label="Description"
        name="description"
        placeholder="Describe your shot"
      />

      <CallToAction.button
        type="submit"
        variant="primary"
        className="w-full mt-4"
        isLoading={isLoading}
        disabled={isLoading}
        leadingIcon={<PromptIcon />}
      >
        Generate
      </CallToAction.button>
    </Field.Form>
  );
};

const ImagePreview = (props: { url: string; onReset: () => void }) => {
  return (
    <div className={styles.ImagePreview}>
      <img
        src={props.url}
        alt="preview"
        className="w-full h-full object-cover"
      />
      <CallToAction.button
        onClick={props.onReset}
        className={styles.ImagePreviewResetCTA}
        size="xs"
        variant="secondary"
      >
        <ResetIcon />
      </CallToAction.button>
    </div>
  );
};
