import React from 'react';

import { Flex } from '@nex/labs';

import LogoMark from '@nex/icons/svg/logo-mark.svg';
import { AnimatorSidebar } from './components/sidebar/index';
import { FloatBar } from '@/components/misc/float-bar';
import Menu from '@nex/icons/svg/misc/menu-alt.svg';
import styles from './animator-layout.module.scss';
import Router from 'next/router';
import classNames from 'classnames';

export const AnimatorLayout = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <div className={styles.AnimatorLayout}>
      <div className={classNames([styles.FloatingNav, styles.NavToolBar])}>
        <Flex className="flex lg:hidden" gap={8} alignItems="center">
          <button
            onClick={() => {
              setOpen(!open);
            }}
            className="flex items-center gap-2 rounded-sm px-[10px] py-[4px] bg-[var(--primary-gray)] text-[14px] font-[600]"
          >
            <Menu className="rotate-180 mr-2" width={14} />
            New Project
          </button>
        </Flex>
      </div>
      <div
        className={classNames([
          styles.AnimatorLayoutSidebar,
          {
            [styles.AnimatorLayoutSidebarOpen]: open,
          },
        ])}
      >
        <button onClick={() => setOpen(!open)} className={styles.Backdrop} />
        <AnimatorSidebar />
      </div>
      <main className={styles.AnimatorLayoutMain}>
        <FloatBar />
        {children}
      </main>
    </div>
  );
};
