import { HeaderNavMenu } from '@/components/layout/views/console-layout/component/header-nav-menu';
import { useGlobalStore, useUserStore } from '@/state/useStore';
import { useAuth, useClerk } from '@clerk/nextjs';
import {
  CallToAction,
  Flex,
  getDataIcons,
  Img,
  isPremiumUser,
  Popover,
  Text,
} from '@nex/labs';
import React from 'react';
import styles from './float-bar.module.scss';
import Router from 'next/router';

export const FloatBar = () => {
  const { profile } = useUserStore();
  const { setModal } = useGlobalStore();
  const handleUpgrade = (e: any) => {
    e.preventDefault();
    const isUpgradeModal = [
      '/artboard',
      '/animator',
      '/enhance',
      '/canvas',
    ].includes(Router.asPath);
    if (isUpgradeModal) {
      setModal({
        type: 'pricing',
        props: {
          size: 'xlg',
        },
      });
      return;
    }
    Router.push('/settings/pricing');
  };

  return (
    <div className={styles.FloatBar}>
      <div className={styles.FloatBarMainHeader}>
        {/* <div className={styles.FloatBarNavMenu}>
          <HeaderNavMenu hideLabel />
          <UserButton />
        </div> */}

        <Text.span weight={600} className={styles.FloatBarCredit}>
          <Flex.Row gap={4}>
            <img src={getDataIcons('help', '#949494')} alt="help" />
            <span>{profile?.credit} Credit</span>
          </Flex.Row>
        </Text.span>

        <span className="bg-transparent m-auto h-fit">
          <UserButton />
        </span>
        {!isPremiumUser(profile) && (
          <CallToAction.a
            href="#"
            onClick={handleUpgrade}
            className={styles.FloatBarUpgrade}
          >
            Upgrade
          </CallToAction.a>
        )}
      </div>
    </div>
  );
};

export const UserButton = () => {
  const { signOut } = useAuth();
  const { user } = useClerk();
  return (
    <Popover>
      <Popover.Trigger>
        <Img
          src={user?.imageUrl!}
          alt="profile"
          width={28}
          height={28}
          className="rounded-[50%]"
        />
      </Popover.Trigger>
      <Popover.Content>
        <Flex.Column gap={8} className="p-2">
          <Flex.Row gap={8} alignItems="flex-start" className="p-2">
            <Img
              src={user?.imageUrl!}
              alt="profile"
              width={40}
              height={40}
              className="rounded-[50%] "
            />
            <Flex.Column>
              <Text weight={700}>{user?.fullName}</Text>
              <Text>{user?.primaryEmailAddress?.emailAddress}</Text>
            </Flex.Column>
          </Flex.Row>
          <CallToAction.a size="xs" variant="clear" href="/settings">
            Settings
          </CallToAction.a>
          <CallToAction.button
            size="xs"
            variant="clear"
            type="button"
            onClick={() => signOut()}
          >
            Logout
          </CallToAction.button>
        </Flex.Column>
      </Popover.Content>
    </Popover>
  );
};
