import { useAppState } from '../state/store';

export const isBlockInView = (meta: string) => {
  const blockInView = useAppState.getState().artboard.blockInView;
  return blockInView.filter((block) => block.meta === meta);
};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
): [(...args: Parameters<T>) => void, () => void] => {
  let timeoutId: NodeJS.Timeout | null = null;

  const debouncedFn = (...args: Parameters<T>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
      timeoutId = null;
    }, delay);
  };

  const cancel = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  return [debouncedFn, cancel];
};
