import { useCallback, useState } from 'react';
import { fabric } from 'fabric';
import { cleanFabricObject } from '../utils';
import { debounce } from '@nex/labs';

const MAX_HISTORY_LENGTH = 50;
const INITIAL_STATE_INDEX = -1;

interface HistoryState {
  version: string;
  objects: fabric.Object[];
}

interface FabricCanvas {
  getObjects(): fabric.Object[];
  loadFromJSON(
    json: string,
    callback?: (this: fabric.Canvas) => void
  ): fabric.Canvas;
  toJSON(propertiesToInclude?: string[]): any;
  renderAll(): fabric.Canvas;
}

interface UseCanvasHistoryProps {
  fabricRef: React.MutableRefObject<fabric.Canvas | null>;
}

interface UseCanvasHistoryReturn {
  saveState: () => void;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
  clearHistory: () => void;
}

export const useCanvasHistory = ({
  fabricRef,
}: UseCanvasHistoryProps): UseCanvasHistoryReturn => {
  const [history, setHistory] = useState<HistoryState[]>([]);
  const [currentStateIndex, setCurrentStateIndex] =
    useState(INITIAL_STATE_INDEX);

  const getCanvas = useCallback((): FabricCanvas | null => {
    return fabricRef.current as FabricCanvas;
  }, [fabricRef]);

  const isValidCanvas = useCallback(
    (canvas: FabricCanvas | null): canvas is FabricCanvas => {
      return canvas !== null;
    },
    []
  );

  const hasValidObjects = useCallback((jsonData: any): boolean => {
    return jsonData.objects && jsonData.objects.length > 0;
  }, []);

  const updateCanvasState = useCallback(
    (canvas: FabricCanvas, newState: HistoryState, callback: () => void) => {
      canvas.loadFromJSON(JSON.stringify(newState), () => {
        canvas.renderAll();
        callback();
      });
    },
    []
  );

  const saveState = useCallback(() => {
    const canvas = getCanvas();

    if (!isValidCanvas(canvas)) return;

    const canvasJsonState = canvas.toJSON();
    const cleanObjects = cleanFabricObject(canvasJsonState.objects);

    if (
      !hasValidObjects({
        objects: cleanObjects,
      })
    )
      return;

    setHistory((prevHistory) => {
      const newHistoryEntry: HistoryState = {
        version: canvasJsonState.version,
        objects: cleanObjects,
      };

      const newHistory = [
        ...prevHistory.slice(0, Math.max(currentStateIndex, 0) + 1),
        newHistoryEntry,
      ];

      return newHistory.length > MAX_HISTORY_LENGTH
        ? newHistory.slice(1)
        : newHistory;
    });

    setCurrentStateIndex((prevIndex) =>
      Math.min(prevIndex + 1, MAX_HISTORY_LENGTH - 1)
    );
  }, [getCanvas, isValidCanvas, hasValidObjects, currentStateIndex]);

  const debounceSaveState = useCallback(() => {
    return debounce(saveState, 500);
  }, [saveState]);

  const canUndo = useCallback((): boolean => {
    return currentStateIndex > 0;
  }, [currentStateIndex]);

  const canRedo = useCallback((): boolean => {
    return currentStateIndex < history.length - 1;
  }, [currentStateIndex, history.length]);

  const undo = useCallback(() => {
    const canvas = getCanvas();

    if (!isValidCanvas(canvas) || !canUndo()) return;

    const newIndex = currentStateIndex - 1;
    const prevState = history[newIndex];

    if (prevState) {
      updateCanvasState(canvas, prevState, () => {
        setCurrentStateIndex(newIndex);
      });
    }
  }, [
    getCanvas,
    isValidCanvas,
    canUndo,
    currentStateIndex,
    history,
    updateCanvasState,
  ]);

  const redo = useCallback(() => {
    const canvas = getCanvas();
    if (!isValidCanvas(canvas) || !canRedo()) return;

    const newIndex = currentStateIndex + 1;
    const nextState = history[newIndex];

    if (nextState) {
      updateCanvasState(canvas, nextState, () => {
        setCurrentStateIndex(newIndex);
      });
    }
  }, [
    getCanvas,
    isValidCanvas,
    canRedo,
    currentStateIndex,
    history,
    updateCanvasState,
  ]);

  const clearHistory = useCallback(() => {
    setHistory([]);
    setCurrentStateIndex(INITIAL_STATE_INDEX);
  }, []);

  return {
    saveState: debounceSaveState(),
    undo,
    redo,
    canUndo: canUndo(),
    canRedo: canRedo(),
    clearHistory,
  };
};
