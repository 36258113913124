import { Attributes, SKAttributes } from '@nex/types/sketch';
import { MyStateCreator } from './store';
import { createRef } from 'react';

export type CanvasSlice = {
  fabric: {
    current: fabric.Canvas | null;
  };
  elementAttributes: Attributes;
  activeTool: {
    value: string;
    attributes?: any;
  };
  popoverBounds: {
    top: number;
    left: number;
    show: boolean;
    reset?: boolean;
  };
  canvasData: SKAttributes;
  layers: Array<{
    id: string;
    type: string;
    name?: string;
    visible: boolean;
    locked: boolean;
    zIndex: number;
    src?: string;
  }>;
  block: {
    image: any;
    mask: any;
    model: any;
    size: number;
    prompt: any;
    negativePrompt: any;
  };
  selectedLayer: any;
  zoom: number;
  panX: number;
  panY: number;
  isPanning: boolean;
  isUserInteracting: boolean;
  actions: {
    setFabric: (fabric: fabric.Canvas) => void;
    setSelectedLayer: (selectedLayer: any) => void;
    setInteracting: (isInteracting: boolean) => void;
    setPopoverBounds: (bounds: CanvasSlice['popoverBounds']) => void;
    setLayers: (layers: any[]) => void;
    setActiveTool: (activeTool: CanvasSlice['activeTool']) => void;
    setCanvasData: (sketch: Partial<SKAttributes>) => void;
    setCanvasLoading: (loading: boolean) => void;
    setElementAttributes: (attributes: Partial<Attributes>) => void;
    setZoom: (zoom: number) => void;
    resetCanvas: () => void;
    setCanvasAppData: (data: any) => void;
    setPan: (panX: number, panY: number) => void;
    setIsPanning: (isPanning: boolean) => void;
    setBlock: (
      block:
        | 'image'
        | 'mask'
        | 'prompt'
        | 'negativePrompt'
        | 'model'
        | 'size'
        | { reset: boolean },
      value: any
    ) => void;
  };
};

const initalState = {
  fabric: {
    current: null,
  },
  activeTool: {
    value: 'select',
  },
  popoverBounds: {
    top: 0,
    left: 0,
    show: false,
  },
  layers: [],
  block: {
    image: null,
    mask: null,
    prompt: null,
    negativePrompt: null,
    model: null,
    size: 3,
  },
  selectedLayer: null,
  canvasData: {
    data: null,
    isEditing: false,
    isDrawing: false,
    realTimeMode: false,
    activeObjectRef: null,
    activeObject: null,
    sketchSize: {
      width: 0,
      height: 0,
      aspectRatio: null,
    },
    loading: false,
  },
  zoom: 1,
  panX: 0,
  panY: 0,
  isPanning: false,
  isUserInteracting: false,
  elementAttributes: {
    width: '',
    height: '',
    left: '',
    top: '',
    strokeWidth: '',
    angle: '',
    rx: '',
    ry: '',
    fill: '',
    stroke: '',
  },
};
export const createCanvasSlice: MyStateCreator<CanvasSlice> = (set) => ({
  ...initalState,
  actions: {
    setInteracting: (isInteracting) => {
      set((state) => {
        if (!isInteracting) {
          state.canvas.block = {
            ...state.canvas.block,
            prompt: null,
            negativePrompt: null,
          };
        }

        state.canvas.isUserInteracting = isInteracting;
      });
    },
    setCanvasLoading: (loading: boolean) => {
      set((state) => {
        state.canvas.canvasData = {
          ...state.canvas.canvasData,
          loading,
        };
      });
    },
    setActiveTool: (activeTool) => {
      set((state) => {
        state.canvas.activeTool = {
          ...state.canvas.activeTool,
          ...activeTool,
        };
      });
    },

    setCanvasAppData: (data: any) => {
      set((state) => {
        state.canvas.canvasData = {
          ...state.canvas.canvasData,
          data: data,
        };
      });
    },
    setBlock: (block, value) => {
      if (typeof block !== 'string' && block.reset) {
        set((state) => {
          state.canvas.block = {
            ...state.canvas.block,
            prompt: null,
            negativePrompt: null,
          };
        });
        return;
      }

      set((state) => {
        state.canvas.block[
          block as
            | 'image'
            | 'mask'
            | 'prompt'
            | 'negativePrompt'
            | 'model'
            | 'size'
        ] = value;
      });
    },
    setElementAttributes: (attributes) =>
      set((state) => {
        state.canvas.elementAttributes = {
          ...state.canvas.elementAttributes,
          ...attributes,
        };
      }),
    setPopoverBounds: (bounds) => {
      set((state) => {
        if (bounds.reset) {
          state.canvas.popoverBounds = {
            top: 0,
            left: 0,
            show: false,
          };
        }
        state.canvas.popoverBounds = bounds;
      });
    },
    setFabric: (fabric) => {
      // fabricRef.current = fabric;
      set((state) => {
        state.canvas.fabric = {
          current: fabric,
        };
      });
    },
    setCanvasData: (sketch) =>
      set((state) => {
        state.canvas.canvasData = { ...state.canvas.canvasData, ...sketch };
      }),

    setLayers: (layers) => {
      set((state) => {
        state.canvas.layers = layers;
      });
    },
    setZoom: (zoom) =>
      set((state) => {
        state.canvas.zoom = zoom;
      }),
    setPan: (panX, panY) =>
      set((state) => {
        state.canvas.panX = panX;
        state.canvas.panY = panY;
      }),
    setIsPanning: (isPanning) =>
      set((state) => {
        state.canvas.isPanning = isPanning;
      }),
    setSelectedLayer: (selectedLayer) => {
      set((state) => {
        state.canvas.selectedLayer = selectedLayer;
      });
    },
    resetCanvas: () => {
      set((state) => {
        Object.keys(state.canvas).forEach((key) => {
          if (Object.keys(initalState).includes(key)) {
            (state.canvas as any)[key] = (initalState as any)[
              key as keyof CanvasSlice
            ];
          }
        });
      });
    },
  },
});
