import { Flex, Heading, Tabs, capitalize } from '@nex/labs';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { Collections } from './views/collections';
import { ConsoleSlateActions } from '@/components/layout';
import { Assets } from './views/assets';
import { Animations } from './views/animations';
import LogoMark from '@nex/icons/svg/logo-mark.svg';
import styles from './library.module.scss';
import { ArtBoards } from './views/artboards';

import { useFileUpload } from '@/components/upload/useUpload';
import { DragDropFileUpload } from '@/components/upload/drag-drop';
import { Canvases } from './views/canvas';
import { AppIcon } from '@/components/misc/app-icon';

export const Library = () => {
  const [currentTab, setCurrentTab] = React.useState('studio');
  const router = useRouter();
  const { onFileUpload } = useFileUpload();

  const getHref = (tab: string) => {
    return `/library/${tab}`;
  };

  useEffect(() => {
    const { tab } = router.query;
    if (tab) {
      setCurrentTab(tab as string);
    }
  }, [router.query]);

  return (
    <div className={styles.ConsoleWrapper}>
      <DragDropFileUpload onFileUpload={onFileUpload} />
      <Flex.Column gap={24} className="mt-4">
        <Heading.h4
          fontSize="28px"
          className="mb-1 flex items-center gap-2"
          weight={700}
        >
          <LogoMark className="inline w-[32px] h-[32px]" />
          <span className="font-light opacity-80">Library/</span>
          <span className="font-bold">{capitalize(currentTab)}</span>
          <br />
        </Heading.h4>
      </Flex.Column>
      <Flex.Column gap={32} className="mt-[24px]">
        <Tabs
          defaultTab="projects"
          bare
          centered={false}
          onChangeTab={(tab) => {
            if (tab === 'projects') {
              return router.push(getHref('studio'));
            }

            return router.push(getHref('uploaded'));
          }}
        >
          <Tabs.Tab dataKey="projects" title="Projects">
            <Flex.Column gap={32} className="mt-[24px]">
              <Tabs
                selectedKey={currentTab}
                defaultTab="studio"
                centered={false}
              >
                {/* <Tabs.Tab dataKey="all" title="All" href={getHref('all')} /> */}
                <Tabs.Tab
                  dataKey="studio"
                  title={
                    <Flex alignItems="center" gap={6}>
                      <AppIcon
                        type="studio"
                        color="#000"
                        width={18}
                        height="auto"
                      />
                      Studio
                    </Flex>
                  }
                  href={getHref('studio')}
                />

                <Tabs.Tab
                  dataKey="animations"
                  title={
                    <Flex alignItems="center" gap={6}>
                      <AppIcon
                        type="animator"
                        color="#000"
                        width={18}
                        height="auto"
                      />
                      Animations
                    </Flex>
                  }
                  href={getHref('animations')}
                />
                <Tabs.Tab
                  dataKey="canvas"
                  title={
                    <Flex alignItems="center" gap={6}>
                      <AppIcon
                        type="canvas"
                        color="#000"
                        width={18}
                        height="auto"
                      />
                      Canvas
                    </Flex>
                  }
                  href={getHref('canvas')}
                />
              </Tabs>

              {/* {currentTab === 'all' && <ArtBoards />} */}
              {currentTab === 'studio' && <ArtBoards />}
              {currentTab === 'animations' && <Animations />}
              {currentTab === 'canvas' && <Canvases />}
            </Flex.Column>
          </Tabs.Tab>
          <Tabs.Tab dataKey="assets" title="Assets">
            <Flex.Column gap={32} className="mt-[24px]">
              <Tabs
                selectedKey={currentTab}
                defaultTab="uploaded"
                centered={false}
              >
                <Tabs.Tab
                  dataKey="uploaded"
                  title="Uploaded"
                  href={getHref('uploaded')}
                />

                <Tabs.Tab
                  dataKey="collections"
                  title="Collections"
                  href={getHref('collections')}
                />
              </Tabs>
              {currentTab === 'collections' && <Collections />}
              {currentTab === 'uploaded' && <Assets />}
            </Flex.Column>
          </Tabs.Tab>
        </Tabs>
      </Flex.Column>
    </div>
  );
};
