// TODO: move to env
const CLOUDFRONT_BASE_DISTRO_URL = 'https://d1pp2vu4n3wr8k.cloudfront.net';

export function createVideoSource(paths: (string | null | undefined)[]) {
  const path = paths
    .filter(Boolean)
    .join('/')
    .replace(/\/{2,}/g, '/');

  const url = new URL(path, CLOUDFRONT_BASE_DISTRO_URL);
  return url.toString();
}
